let dbData = {
    'testing': {db: 'avmams_testing', companyName: 'test Company'},
    'demo': {db: 'avmams_demo', companyName: 'demo Company'},
    'mingji': {db: 'avmams_mingji', companyName: '銘記家鄉小吃'},
    'hjmetal': {db: 'avmams_hjmetal', companyName: '弘鎮金屬股份有限公司'},
    'ppbaby': {db: 'avmams_ppbaby', companyName: '枻心福田好野庄園'},
    'loveejan': {db: 'avmams_loveejan', companyName: '昇泰有機休閒農場'},
    'morale': {db: 'avmams_morale', companyName: '摩絡人工智慧股份有限公司'},
    'astuda': {db: 'avmams_astuda', companyName: '厚田農食所'},
    'pinxuangarden': {db: 'avmams_pinxuangarden', companyName: '品軒園'},
    'hongtaofarm': {db: 'avmams_hongtaofarm', companyName: '弘濤農埸'},
}

export default dbData